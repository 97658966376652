/**
 * Script for Refonte 2018
 * Date: 22/05/2018
 * Time: 16:52
 */

// Global vars

// Global functions

// Document Ready
(function ($) {
    $(function () {
        var setHeroBg = function ( _target ) {
            var _bg ='';

            if ( $(_target).length > 0 ) {
                if ( $(window).width() > 768 ) {
                    _bg = $(_target).attr('data-bg-desk');
                } else {
                    _bg = $(_target).attr('data-bg-tablet');
                }

                $(_target).parents('.wrap-search').eq(0).css({
                    'background-image' : "url(" + _bg + ")"
                });
            }
        };
        $('.boutique-guide a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            setHeroBg ( $(e.target).attr("href") ); // newly activated tab
        })
        if ($('.selection-bloc .slider-bloc').length) {
            $(".selection-bloc .owl-carousel").owlCarousel({
                items: 2,
                loop: true,
                responsive : {
                    0 : {
                        items: 1,
                        stagePadding: 30
                    },
                    768 : {
                        items: 2,
                        stagePadding: 0
                    },
                }
            });
        }
    });
})(jQuery);
